<template>
  <div class="lastBox">
    <!-- <div class="banner">
      <el-input class="search-input" v-model="searchValue" @keyup.enter.native="onUpSearch" @clear="onUpSearch"
        @keypress="event => {if (event.keyCode == 13) return false; }" placeholder="请输入商品名称" clearable></el-input>
      <span @click="onUpSearch">搜索</span>
    </div> -->
    <div class="banner">
      <el-carousel class="banner-carousel" indicator-position="outside">
        <el-carousel-item
          v-for="item in dashboardData.photos"
          :title="item.value3"
          :key="item.id"
        >
          <img
            :title="item.value3"
            :src="item.path"
            @click="linkToResolve(item.value4)"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
      <div class="banner-search">
        <el-input
          class="search-input"
          v-model="searchValue"
          @keyup.enter.native="onUpSearch"
          @clear="onUpSearch"
          @keypress="
            (event) => {
              if (event.keyCode == 13) return false;
            }
          "
          placeholder="请输入商品名称"
          clearable
        ></el-input>
        <span @click="onUpSearch">搜索</span>
      </div>
    </div>
    <div claas="nav-cards">
      <el-card :body-style="{ padding: '10px 0  10px' }" class="box-card">
        <div class="menu">
          <div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="一级:">
                <el-tabs
                  class="tabs-left"
                  v-model="productTypeOneValue"
                  @tab-click="handleClick($event, 'ParentTypeIdOne')"
                >
                  <el-tab-pane
                    v-for="item in productTypeOneList"
                    :key="`${item.id}`"
                    :label="item.name"
                    :name="`${item.id}`"
                  ></el-tab-pane>
                </el-tabs>
              </el-form-item>
              <el-form-item
                label="二级:"
                v-if="
                  productTypeTwoList.length > 1 && productTypeOneValue !== '0'
                "
              >
                <el-tabs
                  class="tabs-left"
                  v-model="ParentTypeIdTwoValue"
                  @tab-click="handleClick($event, 'ParentTypeIdTwo')"
                >
                  <el-tab-pane
                    v-for="item in productTypeTwoList"
                    :key="`${item.id}`"
                    :label="item.name"
                    :name="`${item.id}`"
                  ></el-tab-pane>
                </el-tabs>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-card>
      <el-card :body-style="{ padding: '10px 0  10px' }" class="box-card">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="排序:">
            <el-tabs
              class="tabs-left"
              @tab-click="handleClick($event, 'OrderProduct')"
            >
              <el-tab-pane label="综合" name="22"></el-tab-pane>
              <el-tab-pane name="price">
                <span slot="label">
                  <span class="span-box">
                    <span>价格</span>
                    <i
                      :class="
                        `el-icon-${sortProduct.price === 2 ? 'bottom' : 'top'}`
                      "
                    ></i>
                  </span>
                </span>
              </el-tab-pane>
              <el-tab-pane name="sales">
                <span slot="label">
                  <span class="span-box">
                    <span>销量</span>
                    <i
                      :class="
                        `el-icon-${sortProduct.sales === 4 ? 'bottom' : 'top'}`
                      "
                    ></i>
                  </span>
                </span>
              </el-tab-pane>
              <el-tab-pane name="evaluaNum">
                <span slot="label">
                  <span class="span-box">
                    <span>评价数</span>
                    <i
                      :class="
                        `el-icon-${
                          sortProduct.evaluaNum === 6 ? 'bottom' : 'top'
                        }`
                      "
                    ></i>
                  </span>
                </span>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="sparePartShopList" v-loading="loading">
        <el-card
          @click.native="productDetails(item.id)"
          v-for="item in productList"
          :key="item.id"
          :body-style="{ padding: '10px' }"
          class="sparePartShopList-card"
        >
          <div class="sparePartShopList-card-header">
            <img
              class="sparePartShopList-card-img"
              :src="item.filePath"
              alt=""
            />
          </div>
          <p class="spsl-price">￥{{ item.price }}</p>
          <p class="spsl-title" :title="item.name">{{ item.name }}</p>
          <p class="sparePartShopList-card-font">
            本月已出售 {{ item.saleCount }} 评价数 {{ item.evaluateCount }}
          </p>
        </el-card>
      </div>
      <div class="sparePart-Pagination">
        <Pagination
          @changePagination="changePagination"
          :pageSize="pagination.pageSize"
          :total="pagination.totalCount"
          :pageSizes="[12, 24, 60, 120]"
          :currentPage="pagination.pageIndex"
          :pageMsg="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";

export default {
  name: "adressMaintain",
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage3: 1,
      searchValue: "",
      productTypeOneValue: "0",
      ParentTypeIdTwoValue: "0",
      queryParam: {},
      form: {
        name: "",
        type: [],
        desc: "",
      },
      sortProduct: {
        price: 2,
        sales: 4,
        evaluaNum: 6,
      },
      ParentTypeIdOneName: null,
    };
  },
  computed: {
    ...mapState({
      dashboardData: (state) => {
        return state.home.dashboardData;
      },
      productList: (state) => {
        return state.sparePartsMall.productList;
      },
      productTypeOneList: (state) => {
        return state.sparePartsMall.productTypeOneList;
      },
      productTypeTwoList: (state) => {
        return state.sparePartsMall.productTypeTwoList;
      },
      pagination: (state) => {
        return state.sparePartsMall.pagination;
      },
      loading: (state) => {
        return state.sparePartsMall.loading;
      },
    }),
  },
  created() {
    this.fetch();
  },
  methods: {
    handleClick(event, type) {
      if (type === "ParentTypeIdOne") {
        if (event.name === this.ParentTypeIdOne) return;
        let queryParam = {};
        queryParam.parentId = event.name;
        event.name === "0"
          ? this.$store.commit("sparePartsMall/SET_PRODUCTTYPETWO", {
              list: [],
            })
          : this.$store.dispatch(
              "sparePartsMall/getProductTypeTwo",
              queryParam
            );
        this.queryParam.ParentTypeIdTwo &&
          delete this.queryParam.ParentTypeIdTwo;
        this.ParentTypeIdTwoValue = "0";
        this.ParentTypeIdOne = event.name;
      }
      if (type === "OrderProduct") {
        event.name === "price" &&
          (this.sortProduct[event.name] =
            this.sortProduct[event.name] === 1 ? 2 : 1);
        event.name === "sales" &&
          (this.sortProduct[event.name] =
            this.sortProduct[event.name] === 3 ? 4 : 3);
        event.name === "evaluaNum" &&
          (this.sortProduct[event.name] =
            this.sortProduct[event.name] === 5 ? 6 : 5);
        this.queryParam[type] = this.sortProduct[event.name];
      } else {
        this.queryParam[type] = event.name;
      }
      this.$store.dispatch("sparePartsMall/getProductList", {
        ...this.page,
        ...this.queryParam,
        FindKeyWords: this.searchValue,
        PageSize: 12,
        PageIndex: 1,
      });
    },
    fetch() {
      this.$store.dispatch("sparePartsMall/getProductList", {
        PageSize: 12,
        PageIndex: 1,
      });
      this.$store.dispatch("sparePartsMall/getProductTypeOne");
    },
    onUpSearch() {
      this.productTypeOneValue = "0";
      this.ParentTypeIdTwoValue = "0";
      this.$store.dispatch("sparePartsMall/getProductList", {
        PageIndex: 1,
        PageSize: 12,
        FindKeyWords: this.searchValue,
        ...this.page,
        ...this.queryParam,
      });
    },
    changePagination(page) {
      this.page = { ...this.page, ...page };
      this.$store.dispatch("sparePartsMall/getProductList", {
        PageIndex: 1,
        PageSize: 12,
        FindKeyWords: this.searchValue,
        ...this.page,
        ...this.queryParam,
      });
    },
    productDetails(id) {
      let routeData = this.$router.resolve({
        path: "/sparePartsMall/productDetails",
        query: {
          id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.sparePartShopList-card-header {
  width: 100%;
  height: 200px; /* no */
  margin-bottom: 20px; /* no */
}
.sparePartShopList-card-img {
  width: 100%;
  height: 100%;
}
.sparePartShopList-card-font {
  font-size: 12px; /* no */
  color: #999999;
}
.tabs-left {
  padding-left: 47px; /* no */
}
.box-card {
  width: 62.5%;
  margin: 20px auto; /* no */
}
.banner {
  text-align: center;
  height: 480px; /* no */
  position: relative;
  // background-image: url(/img/banner.png);
  // background-size: 100%;
  // line-height: 480px; /* no */
  .banner-search {
    position: absolute;
    left: 50%;
    top: 32%;
    z-index: 999;
    transform: translateX(-50%);
    .search-input {
      width: 430px; /* no */
      height: 48px; /* no */
      line-height: 380px; /* no */
    }
    span {
      display: inline-block;
      width: 96px; /* no */
      height: 39px; /* no */
      background: linear-gradient(0deg, #181a7d, #30c9cd, #5be196);
      vertical-align: middle;
      margin-bottom: 3px; /* no */
      color: white;
      font-size: 18px; /* no */
      line-height: 39px; /* no */
      cursor: pointer;
    }
  }
  .banner-carousel {
    width: 100%;
    height: 480px; /* no */
    position: relative;
    /deep/.el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    /deep/.el-carousel__indicators--outside {
      position: absolute;
      left: 0;
      width: 100%;
      .el-carousel__button {
        height: 5px; /* no */
      }
    }
  }
}

.sparePart-Pagination {
  margin-bottom: 50px; /* no */
  padding-top: 10px; /* no */
  text-align: center;
}

.lastBox {
  background-image: url(/img/bg1.png);
  background-size: cover;
  background-color: #f8fcff;
}
.nav-cards {
  font-family: SourceHanSansCN-Regular;
}
.sparePartShopList {
  width: 62.5%;
  margin: 0 auto;
  overflow: hidden;
  .sparePartShopList-card {
    cursor: pointer;
    float: left;
    width: 23%;
    padding-bottom: 10px; /* no */
    margin-bottom: 30px; /* no */
    margin-left: 2.4%;
    &:nth-child(4n-3) {
      margin-left: 0;
    }
  }
  .spsl-title {
    font-size: 14px; /* no */
    margin-bottom: 9px; /* no */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .spsl-price {
    font-size: 18px; /* no */
    color: #c91c25;
    margin-bottom: 9px; /* no */
  }
  .sparePartShopList-msg {
    background: #fff;
    line-height: 40px; /* no */
    color: #aaa;
    text-align: center;
  }
}

.sparePartShopList:after {
  content: "";
  width: 23%;
}
/deep/.el-tabs__active-bar {
  background-color: white;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: white;
}

/deep/.el-tabs__item.is-active {
  color: #24a2aa;
}

/deep/.el-tabs__header.is-top {
  margin: 0;
}

/deep/.el-form-item {
  margin: 0;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ffffff;
  border: 1px solid #30af7d; /* no */
  border-radius: 4px; /* no */
  color: #30af7d;
}
</style>
