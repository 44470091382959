<template>
  <div class="pagination">
    <div class="pagination-msg" v-if="pageMsg && total <= 0">暂无相关数据</div>
    <el-pagination
      background
      @size-change="handlechange($event, 'PageSize')"
      @current-change="handlechange($event, 'PageIndex')"
      :current-page="currentPage"
      :page-sizes="pageSizes || [10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: ["pageSize", "total", "currentPage", "pageSizes", "pageMsg"],
  methods: {
    handlechange(value, key) {
      this.$emit("changePagination", { [key]: value });
    }
  }
};
</script>

<style lang="less" scoped>
.pagination {
  text-align: center;
  /deep/.el-pagination {
    font-family: OPPOSans-R;
    .btn-prev,
    .el-pager > li,
    .btn-next {
      width: 30px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: #5c5c5c;
    }
    .el-pagination__sizes {
      height: 30px;
      // border: 1px solid #d9d9d9;
      // border-radius: 4px;
      margin-right: 4px;
      input {
        background: #ffffff;
      }
    }
    .el-pagination__jump {
      height: 30px;
      margin-left: 4px;
      .el-input__inner {
        width: 30px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
      }
    }
  }
  .pagination-msg{
    width: 62.5%;
    margin: 0 auto;
    background:#fff;
    line-height: 40px;
    color: #aaa;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
