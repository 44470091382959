var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lastBox"},[_c('div',{staticClass:"banner"},[_c('el-carousel',{staticClass:"banner-carousel",attrs:{"indicator-position":"outside"}},_vm._l((_vm.dashboardData.photos),function(item){return _c('el-carousel-item',{key:item.id,attrs:{"title":item.value3}},[_c('img',{attrs:{"title":item.value3,"src":item.path,"alt":""},on:{"click":function($event){return _vm.linkToResolve(item.value4)}}})])}),1),_c('div',{staticClass:"banner-search"},[_c('el-input',{staticClass:"search-input",attrs:{"placeholder":"请输入商品名称","clearable":""},on:{"clear":_vm.onUpSearch,"keypress":(event) => {
            if (event.keyCode == 13) return false;
          }},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onUpSearch.apply(null, arguments)}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('span',{on:{"click":_vm.onUpSearch}},[_vm._v("搜索")])],1)],1),_c('div',{attrs:{"claas":"nav-cards"}},[_c('el-card',{staticClass:"box-card",attrs:{"body-style":{ padding: '10px 0  10px' }}},[_c('div',{staticClass:"menu"},[_c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"一级:"}},[_c('el-tabs',{staticClass:"tabs-left",on:{"tab-click":function($event){return _vm.handleClick($event, 'ParentTypeIdOne')}},model:{value:(_vm.productTypeOneValue),callback:function ($$v) {_vm.productTypeOneValue=$$v},expression:"productTypeOneValue"}},_vm._l((_vm.productTypeOneList),function(item){return _c('el-tab-pane',{key:`${item.id}`,attrs:{"label":item.name,"name":`${item.id}`}})}),1)],1),(
                _vm.productTypeTwoList.length > 1 && _vm.productTypeOneValue !== '0'
              )?_c('el-form-item',{attrs:{"label":"二级:"}},[_c('el-tabs',{staticClass:"tabs-left",on:{"tab-click":function($event){return _vm.handleClick($event, 'ParentTypeIdTwo')}},model:{value:(_vm.ParentTypeIdTwoValue),callback:function ($$v) {_vm.ParentTypeIdTwoValue=$$v},expression:"ParentTypeIdTwoValue"}},_vm._l((_vm.productTypeTwoList),function(item){return _c('el-tab-pane',{key:`${item.id}`,attrs:{"label":item.name,"name":`${item.id}`}})}),1)],1):_vm._e()],1)],1)])]),_c('el-card',{staticClass:"box-card",attrs:{"body-style":{ padding: '10px 0  10px' }}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"排序:"}},[_c('el-tabs',{staticClass:"tabs-left",on:{"tab-click":function($event){return _vm.handleClick($event, 'OrderProduct')}}},[_c('el-tab-pane',{attrs:{"label":"综合","name":"22"}}),_c('el-tab-pane',{attrs:{"name":"price"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"span-box"},[_c('span',[_vm._v("价格")]),_c('i',{class:`el-icon-${_vm.sortProduct.price === 2 ? 'bottom' : 'top'}`})])])]),_c('el-tab-pane',{attrs:{"name":"sales"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"span-box"},[_c('span',[_vm._v("销量")]),_c('i',{class:`el-icon-${_vm.sortProduct.sales === 4 ? 'bottom' : 'top'}`})])])]),_c('el-tab-pane',{attrs:{"name":"evaluaNum"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"span-box"},[_c('span',[_vm._v("评价数")]),_c('i',{class:`el-icon-${
                        _vm.sortProduct.evaluaNum === 6 ? 'bottom' : 'top'
                      }`})])])])],1)],1)],1)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"sparePartShopList"},_vm._l((_vm.productList),function(item){return _c('el-card',{key:item.id,staticClass:"sparePartShopList-card",attrs:{"body-style":{ padding: '10px' }},nativeOn:{"click":function($event){return _vm.productDetails(item.id)}}},[_c('div',{staticClass:"sparePartShopList-card-header"},[_c('img',{staticClass:"sparePartShopList-card-img",attrs:{"src":item.filePath,"alt":""}})]),_c('p',{staticClass:"spsl-price"},[_vm._v("￥"+_vm._s(item.price))]),_c('p',{staticClass:"spsl-title",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"sparePartShopList-card-font"},[_vm._v(" 本月已出售 "+_vm._s(item.saleCount)+" 评价数 "+_vm._s(item.evaluateCount)+" ")])])}),1),_c('div',{staticClass:"sparePart-Pagination"},[_c('Pagination',{attrs:{"pageSize":_vm.pagination.pageSize,"total":_vm.pagination.totalCount,"pageSizes":[12, 24, 60, 120],"currentPage":_vm.pagination.pageIndex,"pageMsg":true},on:{"changePagination":_vm.changePagination}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }